import request from '@/utils/request'

export function viewFile(data) {
  return request({
 	url: 'community/auth/file/view',
    data,
    method: 'post'
  })
}

export function uploadFile(data) {
  return request({
 	url: 'community/auth/file/upload',
    data,
    method: 'post'
  })
}

export function batchView(data) {
  return request({
    url: '/community/auth/file/batchView',
    method: 'post',
    data
  })
}

<template>
  <div>
    <el-button size="mini" type="success" @click="to">查看</el-button>
  </div>
</template>
<script>
import { viewFile} from '@/api/system/dims'
export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      fileName:"",
      herfUrl:"",
    }
  },
  methods: {
    to() {
      let formdata={
        'fileName':this.data.fileName
      }
      viewFile(formdata).then(res => {
        this.herfUrl=res.fileUrl;
        window.open(this.herfUrl, '_blank');
      }).catch(err => {
      })
    },
  }
}
</script>

<style scoped>
</style>
